import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Beachwear from '../../components/Campanha/ColecaoBeachwear'

function ColecaoBeachwear2022() {
  return (
    <>
      <GatsbySeo title="Campanha | Decathlon" />
      <Beachwear />
    </>
  )
}

export default ColecaoBeachwear2022
